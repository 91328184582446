import React, { useMemo } from 'react';
import { DatePickerDataHooks } from './dataHooks.const';
import { classes, st } from './DatePicker.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { DatePicker as DatePickerComponent } from 'wix-ui-tpa/DatePicker';
import {
  DateAvailabilityStatus,
  DatePickerViewModel,
} from '../../ViewModel/datePickerViewModel/datePickerViewModel';
import { getLocalDateTimeStartOfDay } from '../../../../utils/dateAndTime/dateAndTime';
import { dateIndicationProps } from 'wix-ui-tpa/dist/src/components/DatePicker/DatePicker';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { TriggeredByOptions } from '../../../../utils/bi/consts';
import { ReactComponent as DotIcon } from '../../../../assets/Icons/DotIndication.svg';

export type DatePickerProps = {
  datePickerViewModel: DatePickerViewModel;
};

const DotIndication: React.FC<{ hasAvailableSlots?: boolean }> = (
  { hasAvailableSlots } = { hasAvailableSlots: false },
) => {
  return (
    <div className={classes.dateIndicationContainer}>
      <DotIcon
        data-hook={DatePickerDataHooks.DOT_ICON}
        className={
          hasAvailableSlots
            ? classes.availableDateIndication
            : classes.notAvailableDateIndication
        }
      />
    </div>
  );
};

const DatePicker: React.FC<DatePickerProps> = ({ datePickerViewModel }) => {
  const { isMobile, isRTL } = useEnvironment();
  const {
    selectedDate: localDateTime,
    dateAvailabilityStatuses,
    firstDayOfTheWeek,
    todayLocalDateTime,
    locale,
    nextMonthLabel,
    prevMonthLabel,
  } = datePickerViewModel;
  const { onDateSelected, onMonthSelected } = useCalendarActions();

  const onDateChange = async (date: Date) => {
    const localDateTimeStartOfDay = getLocalDateTimeStartOfDay(date);
    await onDateSelected(
      localDateTimeStartOfDay,
      TriggeredByOptions.DATE_SELECTED,
    );
  };

  const onMonthChange = async (date: Date) => {
    const localDateTimeStartOfDay = getLocalDateTimeStartOfDay(date);
    await onMonthSelected(localDateTimeStartOfDay);
  };

  const dateIndications = ({ date, isSelected }: dateIndicationProps) => {
    const dateAvailabilityStatus = dateAvailabilityStatuses.get(
      getLocalDateTimeStartOfDay(date),
    );
    if (!isSelected && dateAvailabilityStatus) {
      return (
        <DotIndication
          hasAvailableSlots={
            dateAvailabilityStatus ===
            DateAvailabilityStatus.HAS_AVAILABLE_SLOTS
          }
        />
      );
    }
    return null;
  };

  const value = useMemo(() => new Date(localDateTime), [localDateTime]);
  const today = useMemo(() => new Date(todayLocalDateTime), [
    todayLocalDateTime,
  ]);

  return (
    <div className={st(classes.root, { isMobile, isRTL })}>
      <DatePickerComponent
        data-hook={DatePickerDataHooks.DATE_PICKER}
        className={classes.datePicker}
        value={value}
        today={today}
        onChange={onDateChange}
        onMonthChange={onMonthChange}
        dateIndication={dateIndications}
        firstDayOfWeek={firstDayOfTheWeek}
        rightArrowAriaLabel={nextMonthLabel}
        leftArrowAriaLabel={prevMonthLabel}
        removeOuterPadding
        locale={locale}
      />
    </div>
  );
};

export default DatePicker;
